<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded v-if="registro.gesproducto">
    <v-card flat v-if="registro.gesproducto">
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>PRODUCTO</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'TIPO DE PRODUCTO'" :descripcion="(registro.gesproducto.producto)?registro.gesproducto.producto.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'¿ES UN PRODUCTO DIGITAL?'" :descripcion="(registro.gesproducto.productodigital)?registro.gesproducto.productodigital.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4">
            <texto2 :colores="colores" :titulo="'No. DE PRODUCTO'" :descripcion="registro.gesproducto.numero"></texto2>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'PAÍS'" :descripcion="(registro.gesproducto.pais)?registro.gesproducto.pais.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'DEPARTAMENTO'" :descripcion="(registro.gesproducto.departamento)?registro.gesproducto.departamento.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4">
            <texto2 :colores="colores" :titulo="'MUNICIPIO'" :descripcion="(registro.gesproducto.municipio)?registro.gesproducto.municipio.descripcion:''"></texto2>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters v-if="registro.gesvehiculo">
          <v-col cols="12">
            <texto2 :colores="colores" :titulo="'PLACA DEL VEHÍCULO'" :descripcion="registro.gesvehiculo.placa"></texto2>
          </v-col>
        </v-row>
        <v-divider v-if="registro.gesproducto.vehiculo"></v-divider>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
// import texto2 from '@/components/widgets/text2Component.vue'
export default {
  name: 'crm_lectura_clienteComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue')
  },
  props: ['colores', 'registro'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }

}
</script>
